import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";

const AgentSelector = ({ user, agents, selectedAgent, onSelectAgent }) => {
    const renderAgentOptions = (agents, parentId = null, level = 1) => {
        return agents
            .filter(agent => agent.parent === parentId)
            .map(agent => (
                <React.Fragment key={agent.id}>
                    <option value={agent.prescriptor}>
                        {'-'.repeat(level)} {agent.name}
                    </option>
                    {renderAgentOptions(agents, agent.id, level + 1)}
                </React.Fragment>
            ));
    };

    return (
        <div className="row mb-2 d-flex justify-content-center">
            <div className="col-md-6">
                <Form.Group as={Row}>
                    <Form.Label column md={4}>Seleccionar Agente:</Form.Label>
                    <Col md={8}>
                        <Form.Select
                            className="form-control"
                            value={selectedAgent}
                            onChange={(e) => onSelectAgent(e.target.value)}
                        >
                            <option key={-1} value="all">Todos</option>
                            <option key={0} value={user.prescriptor}>{user.get_full_name}</option>
                            {renderAgentOptions(agents, user.id)}
                        </Form.Select>
                    </Col>
                </Form.Group>
            </div>
            <hr className="mt-3" />
        </div>
    );
};

AgentSelector.propTypes = {
    agents: PropTypes.array.isRequired,
    selectedAgent: PropTypes.string.isRequired,
    onSelectAgent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.Auth.user
});

export default connect(mapStateToProps)(AgentSelector);
