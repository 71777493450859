import http from "../utils/http";
import { toast } from "react-toastify";
import { store } from "../store";
import { removeLoader, setLoader } from "../redux/actions/loader";

export const getTipovias = async () => {
  try {
    const res = await http.get(`/erp/cups`, { params: { tipovia: "tipovia" } });
    res.data.tipovia.unshift({ id: 0, name: "Tipo de vía" });
    return res.data.tipovia;
  } catch (err) {
    toast.error("Error al obtener los tipos de vía", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const getPaises = async () => {
  try {
    const res = await http.get(`/erp/cups`, { params: { pais: "pais" } });
    res.data.paises.unshift({ id: 0, value: "", name: "Seleccione país" });
    return res.data.paises;
  } catch (err) {
    toast.error("Error al obtener los países", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const getProvincias = async (country_id) => {
  try {
    const res = await http.get(`/erp/cups`, { params: { country_id } });
    if (res.status === 200) {
      res.data.provincias.unshift({ id: 0, value: "", name: "Seleccione provincia" });
      return res.data.provincias;
    } else {
      toast.info("No se encontraron provincias", {
        position: toast.POSITION.TOP_CENTER,
      });
      return []
    }
  } catch (err) {
    toast.error("Error al obtener las provincias", {
      position: toast.POSITION.TOP_CENTER,
    });
    return []
  }
};

export const getMunicipios = async (state) => {
  try {
    const res = await http.get(`/erp/cups`, { params: { state } });
    if (res.status === 200) {
      res.data.municipios.unshift({ id: 0, value: "", name: "Seleccione municipio" });
      return res.data.municipios;
    } else {
      toast.info("No se encontraron municipios", {
        position: toast.POSITION.TOP_CENTER,
      });
      return []
    }
  } catch (err) {
    toast.error("Error al obtener los municipios", {
      position: toast.POSITION.TOP_CENTER,
    });
    return []
  }
};

export const getPoblaciones = async (municipi_id) => {
  try {
    const res = await http.get(`/erp/cups`, { params: { municipi_id } });
    if (res.status === 200) {
      res.data.poblaciones.unshift({ id: 0, value: "", name: "Seleccione población" });
      return res.data.poblaciones;
    } else {
      toast.info("No se encontraron poblaciones", {
        position: toast.POSITION.TOP_CENTER,
      });
      return []
    }
  } catch (err) {
    toast.error("Error al obtener las poblaciones", {
      position: toast.POSITION.TOP_CENTER,
    });
    return []
  }
};

export const getBanks = async (iban) => {
  try {
    store.dispatch(setLoader("Buscando banco"));
    const res = await http.get(`/erp/pagador`, {
      params: { banks: "banks", iban },
    });
    return res.data.banks;
  } catch (err) {
    toast.error("Error al obtener el banco", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const getDistribuidoras = async (cups_name) => {
  try {
    // store.dispatch(setLoader("Buscando distribuidora"));
    const res = await http.get(`/erp/cups`, {
      params: { distribuidoras: "distribuidoras", cups_name },
    });
    return res.data.distribuidoras;
  } catch (err) {
    toast.error("Error al obtener la distribuidora", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    // store.dispatch(removeLoader());
  }
};

export const getDistribuidorasGas = async () => {
  try {
    store.dispatch(setLoader("Buscando distribuidoras"));
    const res = await http.get(`/erp/cups`, {
      params: { distribuidoras_gas: "distribuidoras_gas" },
    });
    const distribuidoras = res.data.distribuidoras.map((dist) => {
      return { id: dist.partner_id[0], name: dist.partner_id[1] };
    });
    distribuidoras.unshift({ id: 0, name: "Seleccionar" });
    return distribuidoras;
  } catch (err) {
    toast.error("Error al obtener la distribuidora", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const getCnaeList = async () => {
  try {
    store.dispatch(setLoader("Cargando lista de CNAE"));
    const res = await http.get(`/erp/cups`, {
      params: { get_cnae_list: "get_cnae_list" },
    });
    res.data.cnaes.unshift({ id: 0, name: "Seleccionar" });
    return res.data.cnaes;
  } catch (err) {
    toast.error("Error al obtener la lista de CNAE", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const print_contrato_pdf = async (user_id, oferta_id, polissa_id) => {
  try {
    store.dispatch(setLoader("Generando contrato"));
    const res = await http.get(`/contratos/archivos`, {
      params: {
        oferta_id,
        user_id,
        polissa_id,
        contratoPdf: "contratoPdf",
      },
    });
    return res.data;
  } catch (err) {
    const mensaje = err.response.data.error;
    toast.error(mensaje, {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

