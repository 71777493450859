import { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Table, Stack } from "react-bootstrap";
import { getNotasOferta } from "../../services/notas";
import { CiWarning } from "react-icons/ci";

function NotasOferta({ oferta, show, close }) {
  const [loading, setLoading] = useState(false);
  const [notas, setNotas] = useState(null);

  useEffect(() => {
    if (show && oferta) {
      const fetchNotas = async () => {
        setLoading(true);
        const res = await getNotasOferta(oferta.id);
        setNotas(res);
        setLoading(false);
      };
      fetchNotas();
    }
  }, [show, oferta]);

  const closeDialog = () => {
    close(false);
  };

  const dateCellStyles = {
    whiteSpace: "nowrap",
    width: "120px",
  };
  
  const noteCellStyles = {
    wordWrap: "break-word",
    whiteSpace: "normal",
    minWidth: "300px",
  };


  return (
    <Modal
      show={show}
      size="lg"
      backdrop="static"
      className="border border-0"
      centered
    >
      <Modal.Header className="ab-primary justify-content-between align-items-center">
        <Col md="auto">
          <h2 className="text-white my-0">Notas de la oferta</h2>
        </Col>
        <Col md="auto">
          <h2 className="text-white my-0">Nº {oferta?.name}</h2>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center align-items-center">
          {loading ? (
            <Col
              style={{ height: "40vh" }}
              className="justify-content-center d-flex"
            >
              <Stack direction="horizontal" gap={2}>
                <span className="spinner-border spinner-border text-ab"></span>
                <h3 className="mb-0">Cargando notas</h3>
              </Stack>
            </Col>
          ) : notas && notas.length > 0 ? (
            <Col
              style={{ height: "40vh", overflowY: "auto", overflowX: "hidden" }}
              className="px-2"
            >
              <Table
                maxheight="20px"
                striped
                bordered
                hover
                size="lg"
                responsive
                className="overflow-hidden"
              >
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Nota</th>
                  </tr>
                </thead>
                <tbody>
                  {notas.map((nota) => (
                    <tr key={nota.id} className="align-middle">
                      <td style={dateCellStyles}>{nota.date_event}</td>
                      <td style={noteCellStyles}>{nota.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          ) : (
            <Col md="12" style={{ height: "40vh" }} className="pt-4">
              <Row className="justify-content-center">
                <Col md="auto">
                  <h1 className="error-title d-flex justify-content-center">
                    <CiWarning />
                  </h1>
                  <h1 className="text-gray">No hay notas para esta oferta.</h1>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col md="auto">
          <Button
            className="button rounded-5 button-secondary border-0"
            variant="secondary"
            onClick={closeDialog}
          >
            Cerrar
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

export default NotasOferta;