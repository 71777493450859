import Select from 'react-select';
import './AutocompleteInput.css';

const AutocompleteInput = ({ options, value, onChange, placeholder }) => {
  return (
    <Select
      className='w-100'
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isClearable
      isSearchable
      classNamePrefix="select-autocomplete"
    />
  );
};

export default AutocompleteInput;