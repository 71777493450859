import axios from "axios";

const http = axios.create();

http.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
http.defaults.headers.common.Accept = "application/json";

// Interceptor to add the token to the request headers
http.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("access");
      if (token) {
        config.headers.Authorization = `JWT ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default http;
