import { Badge } from "react-bootstrap";

export const sectorTypeBadge = (sector_type) => {
    return sector_type === "electrico" ? (
        <Badge pill bg="warning" text="dark">
            ELECTRICIDAD
        </Badge>
    ) : (
        <Badge pill bg="info" text="dark">
            GAS
        </Badge>
    );
};


// Función para determinar el tipo MIME y la extensión
export const determineContentTypeAndExtension = (base64Data) => {
    if (base64Data.startsWith("iVBORw0KGgo")) {
        return { contentType: "image/png", extension: "png" };
    }
    if (base64Data.startsWith("/9j/")) {
        return { contentType: "image/jpeg", extension: "jpg" };
    }
    if (base64Data.startsWith("JVBERi0")) {
        return { contentType: "application/pdf", extension: "pdf" };
    }
    // Añade más tipos si es necesario
    return { contentType: "application/octet-stream", extension: "bin" }; // Valor por defecto
};
