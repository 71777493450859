import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import './Pagination.css';
import PropTypes from 'prop-types';

function PaginationComponent({ totalPages, currentPage, onPageChange, totalRecords }) {
  const handlePageChange = (page) => {
    if (page !== currentPage && page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const getPaginationRange = () => {
    const delta = window.innerWidth < 768 ? 1 : 2; // Menos índices en pantallas pequeñas
    const range = [];
    for (let i = Math.max(1, currentPage - delta); i <= Math.min(totalPages, currentPage + delta); i++) {
        range.push(i);
    }
    if (range[0] > 1) range.unshift('...');
    if (range[range.length - 1] < totalPages) range.push('...');
    return range;
};


  return (
    <div className="row mb-1">
      <div className="pagination-container">
        <Pagination className="custom-pagination">
          <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} aria-label="Primera página" />
          <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="Página anterior" />
          {getPaginationRange().map((page, index) =>
            page === '...' ? (
              <Pagination.Ellipsis key={'ellipsis'+index} disabled />
            ) : (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </Pagination.Item>
            )
          )}
          <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} aria-label="Página siguiente" />
          <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} aria-label="Última página" />
        </Pagination>
        <div className="total-records text-secondary">{totalRecords} registros encontrados.</div>
      </div>
    </div>
  );
}

PaginationComponent.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

export default PaginationComponent;
