import Layout from "../../hocs/Layout";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Row,
  Table,
  Badge,
} from "react-bootstrap";
import { connect } from "react-redux";
import { get_ofertas_erp, aceptar_oferta, get_ofertas_excel } from "../../services/ofertas";
import { print_contrato_pdf } from "../../services/auxiliares";
import { FaCheck } from "react-icons/fa";
import { CiWarning } from "react-icons/ci";
import { MdEdit, MdDownload } from "react-icons/md";
import { Dropdown, DropdownButton } from "react-bootstrap";
import EditarOferta from "../../components/modals/EditarOferta";
import PaginationComponent from "../../components/pagination/Pagination";
import OfertasFilter from "../../components/ofertas/OfertasFilter";
import AgentSelector from '../../components/inputs/AgentSelector';
import UserAutocompleteInput from "../../components/inputs/UserAutoCompleteInput";
import { send_document_signaturit } from "../../services/signaturit";
import GestionArchivosOferta from "../../components/modals/GestionArchivosOferta";
import { sectorTypeBadge, determineContentTypeAndExtension } from "../../utils/ofertasUtils";
import NotasOferta from "../../components/modals/NotasOferta";
import { LuFiles, LuClipboardList } from "react-icons/lu";


const tipoOferta = {
  simple: "CONTRATO NUEVO",
  renovacion: "RENOVACIÓN",
};


const OfertasList = ({ user, isAuthenticated }) => {
  const [showCartera, setShowCartera] = useState(false);
  const [oferta, setOferta] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ofertas_erp, setOfertasErp] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [selectedAgent, setSelectedAgent] = useState(user.prescriptor);
  const [ofertaActiva, setOfertaActiva] = useState(false);
  const [showGestionArchivos, setShowGestionArchivos] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(user.id);
  const [agents, setAgents] = useState("");
  const [selectedAgentOption, setSelectedAgentOption] = useState(user.prescriptor);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showNotas, setShowNotas] = useState(false);
  const [ofertaNotas, setOfertaNotas] = useState(null);

  useEffect(() => {
    const getOfertas = async (page) => {
      setLoading(true);
      setOfertasErp(await get_ofertas_erp(selectedAgent, page, agents, filters));
      setLoading(false);
    };
    if (isAuthenticated) {
      getOfertas(currentPage);
    }
    window.scrollTo(0, 0);
  }, [isAuthenticated, selectedAgent, currentPage, agents, filters]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilter = (newFilters) => {
    setCurrentPage(1);
    setFilters(newFilters);
  };

  const handleExportExcel = async () => {
    await get_ofertas_excel(selectedAgent, agents, filters);
  }

  const handleAgentSelect = (agentPrescriptor) => {
    if (agentPrescriptor === "all") { // When viewing all agents, current logged user is the prescriptor
      setSelectedAgent(user.prescriptor);
      setSelectedAgentOption("all");
      setAgents("all");
      setSelectedAgentId(getUserIdFromAgent(user.agents, user.prescriptor));
    }
    else {
      setSelectedAgent(agentPrescriptor);
      setSelectedAgentOption(agentPrescriptor);
      setAgents("");
      setSelectedAgentId(getUserIdFromAgent(user.agents, agentPrescriptor));
    }

    setCurrentPage(1);
  };

  const openGestionArchivos = (id, name ) => {
    setOfertaActiva({ id, name  });
    setShowGestionArchivos(true);
  };

  const getUserIdFromAgent = (agents, prescriptor) => {
    if (!agents) {
      return user.id;
    }
    const agent = agents.find((agent) => agent.prescriptor === prescriptor);
    return agent ? agent.id : user.id;
  };

  const getOfertas = async () => {
    setLoading(true);
    setOfertasErp(await get_ofertas_erp(selectedAgent, currentPage, agents, filters));
    setLoading(false);
  };

  const aceptarOferta = async (id, sector_type) => {
    await aceptar_oferta(id, selectedAgentId, sector_type);
    setOfertasErp(await get_ofertas_erp(selectedAgent, currentPage, agents, filters));
  };

  const sendDocumentSignaturit = async (oferta_id, signature_type) => {
    try {
      await send_document_signaturit(oferta_id, signature_type);
    } catch (error) {
      console.error("Error al enviar archivo a Signaturit:", error);
    }
    getOfertas();
  };

  const contratoPdf = async (user_id, id, polissa_id) => {
    try {
      const archivo = await print_contrato_pdf(user_id, id, polissa_id);
      Object.keys(archivo).forEach((arch) => {
        const { datas: base64Data, name: originalName } = archivo[arch];
        const { contentType, extension } = determineContentTypeAndExtension(base64Data);

        // Decodifica el base64
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Crea un Blob con el tipo MIME adecuado
        const blob = new Blob([byteArray], { type: contentType });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        // Usa el nombre original si está disponible, o genera un nombre genérico
        link.download = originalName || `archivo.${extension}`;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }

    getOfertas();
  };

  const openModalCartera = (id, name, sector_type) => {
    setOferta({ id, name, sector_type });
    setShowCartera(true);
  };

  const openNotas = (id, name) => {
    setOfertaNotas({ id, name });
    setShowNotas(true);
  };

  return (
    <Layout lg={11} md={12} title="Ofertas">
      {user.is_superadmin && (
        <UserAutocompleteInput
            value={selectedUser}
            placeholder="Nombre del usuario..."
            onChange={(selectedOption) => {
              setSelectedUser(selectedOption);
              handleAgentSelect(selectedOption ? selectedOption.value : selectedAgentOption);
            }}
          />
      )}

      {!user.is_superadmin && user.agents && user.agents.length > 0 && (
          <AgentSelector
            agents={user.agents}
            selectedAgent={selectedAgentOption}
            onSelectAgent={handleAgentSelect}
          />
      )}

      <OfertasFilter onFilter={handleFilter} onExport={handleExportExcel} />
      <div className="row justify-content-center align-items-center mt-5">
        {loading ? (
          <></>
        ) : (ofertas_erp.ofertas && ofertas_erp.ofertas.length > 0) ? (
          <>
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th className="col-md-1">Sector<br/>Tipo oferta</th>
                  <th className="col-md-1">Nº oferta</th>
                  <th className="col-md-1">Fecha</th>
                  <th className="col-md-1">Etapa</th>
                  <th className="col-md-2">Cliente</th>
                  <th className="col-md-1">CIF</th>
                  <th className="col-md-2">CUPS</th>
                  {user.agents && user.agents.length > 0 && (
                    <th className="col-md-1 ">Agente</th>
                  )}
                  <th className="col-md-2">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {ofertas_erp.ofertas.map((oferta) => {
                  return (
                    <tr key={oferta.name} className="align-middle">
                      <td>
                        {sectorTypeBadge(oferta.sector_type)}
                        <br />
                        <Badge pill bg="dark">
                          {tipoOferta[oferta.type]}
                        </Badge>
                      </td>
                      <td>{oferta.name}</td>
                      <td>{oferta.create_date}</td>
                      <td>
                        <Badge pill bg="primary">
                          {oferta.state}
                        </Badge>
                      </td>
                      <td>
                          {oferta.cliente_apellidos ||
                          oferta.cliente_nombre ? (
                            <span>
                              {oferta.cliente_apellidos},{" "}
                              {oferta.cliente_nombre}
                            </span>
                          ) : (
                            <span> — </span>
                          )}
                      </td>
                      <td>
                          <span>
                            {oferta.cliente_vat ? (
                              <span>
                                {oferta.cliente_vat}
                              </span>
                            ) : (
                              <span> — </span>
                            )}
                          </span>
                      </td>

                      <td>{oferta.cups_name}</td>

                      {user.agents && user.agents.length > 0 && (
                        <td>
                          {oferta.prescriptor}
                        </td>
                      )}
                      <td>
                        <DropdownButton
                          id={`dropdown-actions-${oferta.id}`}
                          title="Acciones"
                          variant="secondary"
                          size="sm"
                        >
                          {/* Acciones específicas para tipo 'simple' y estado 'Solicitud oferta' */}
                          {oferta.type === "simple" &&  (oferta.state  === ("Solicitud oferta") ||  oferta.state === ("Contrato enviado")) && (
                            <>
                              <Dropdown.Item
                                onClick={() => aceptarOferta(oferta.id, oferta.sector_type)}
                              >
                                <FaCheck /> Aceptar oferta
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  openModalCartera(oferta.id, oferta.name, oferta.sector_type)
                                }
                              >
                                <MdEdit /> Modificar datos
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  contratoPdf(oferta.user_id, oferta.id, oferta.polissa_id[0])
                                }
                              >
                                <MdDownload /> Imprimir contrato
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => sendDocumentSignaturit(oferta.id, 'email')}
                              >
                                <MdDownload /> Signaturit Email
                              </Dropdown.Item>
                              {/*
                              <Dropdown.Item
                                onClick={() => sendDocumentSignaturit(oferta.id, 'sms')}
                              >
                                <MdDownload /> Signaturit SMS
                              </Dropdown.Item>
                              */}
                            </>
                          )}

                          {/* Acciones comunes */}
                          {oferta.type !== "simple" && (oferta.state  === ("Solicitud oferta") ||  oferta.state === ("Contrato enviado")) &&(
                            <>
                              <Dropdown.Item
                                onClick={() =>
                                  contratoPdf(oferta.user_id, oferta.id, oferta.polissa_id[0])
                                }
                              >
                                <MdDownload /> Imprimir contrato
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  openModalCartera(oferta.id, oferta.name, oferta.sector_type)
                                }
                              >
                                <MdEdit /> Modificar datos
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => sendDocumentSignaturit(oferta.id, 'email')}
                              >
                                <MdDownload /> Signaturit Email
                              </Dropdown.Item>
                              {/*
                              <Dropdown.Item
                                onClick={() => sendDocumentSignaturit(oferta.id,'sms')}
                              >
                                <MdDownload /> Signaturit SMS
                              </Dropdown.Item>
                              */}
                            </>
                          )}

                          {/* Acción 'Gestionar archivos' (común a todos los casos) */}
                          <Dropdown.Item
                            onClick={() => openGestionArchivos(oferta.id, oferta.name)}
                          >
                            <LuFiles /> Gestionar archivos
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => openNotas(oferta.id, oferta.name)}>
                            <LuClipboardList /> Ver notas
                          </Dropdown.Item>
                        </DropdownButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <PaginationComponent
              totalPages={ofertas_erp.total_pages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              totalRecords={ofertas_erp.total_records}>
            </PaginationComponent>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <h1 className="error-title">
                  <CiWarning />
                </h1>
                <h1 className="text-gray">No se han encontrado ofertas.</h1>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Col md="auto">
                <Button
                  className="button rounded-5 button-block"
                  type="submit"
                  variant="secondary"
                  onClick={() => getOfertas()}
                >
                  Reintentar
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
      <EditarOferta show={showCartera} close={setShowCartera} oferta={oferta} />
      <GestionArchivosOferta
        show={showGestionArchivos}
        close={setShowGestionArchivos}
        oferta={ofertaActiva}
        onClose={getOfertas}
      />
      <NotasOferta
        show={showNotas}
        close={setShowNotas}
        oferta={ofertaNotas}
      />      
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps)(OfertasList);
