import http from "../utils/http";
import { toast } from "react-toastify";
import { store } from "../store";
import { removeLoader, setLoader } from "../redux/actions/loader";

export const get_archivo_by_id = async (attach_id) => {
    try {
      store.dispatch(setLoader("Cargando archivo"));
      const res = await http.get(`/contratos/archivos`, {
        params: {
          getArchivoByAttachId: "getArchivoByAttachId",
          attach_id,
        },
      });
      return res.data;
    } catch (err) {
      toast.error("Error al obtener el archivo.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      store.dispatch(removeLoader());
    }
  };
  
  export const get_archivos = async (polissa_id, sector_type) => {
    try {
      const res = await http.get(`/contratos/archivos`, {
        params: {
          getArchivosByContratoId: "getArchivosByContratoId",
          polissa_id,
          sector_type,
        },
      });
      return res.data;
    } catch (err) {
      toast.error("Error al obtener los archivos.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
    }
  };
  
  export const get_archivos_oferta = async (oferta_id) => {
    try {
      const res = await http.get(`/contratos/archivos`, {
        params: {
          getArchivosByOfertaId: "getArchivosByOfertaId",
          oferta_id,
        },
      });
      return res.data;
    } catch (err) {
      toast.error("Error al obtener los archivos.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
    }
  };
  
  export const post_archivo = async (file, params) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      params.tipo="contrato";
      store.dispatch(setLoader("Subiendo archivo"));
      const res = await http.post(`/contratos/archivos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params,
      });
      toast.success("Archivo subido con éxito.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return res.data.contratos_erp;
    } catch (err) {
      toast.error("Error al subir el archivo.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      store.dispatch(removeLoader());
    }
  };

  export const post_archivo_oferta = async (file, params) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      params.tipo="oferta";
      store.dispatch(setLoader("Subiendo archivo"));
      const res = await http.post(`/contratos/archivos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params,
      });
      toast.success("Archivo subido con éxito.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return res.data.contratos_erp;
    } catch (err) {
      toast.error("Error al subir el archivo.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      store.dispatch(removeLoader());
    }
  };
  
  export const delete_archivo = async (attach_id) => {
    try {
      store.dispatch(setLoader("Eliminando archivo"));
      const tipo = "contrato";
      const res = await http.delete(`/contratos/archivos`, {
        params: { attach_id, tipo },
      });
      return res.data;
    } catch (err) {
      toast.error("Error al eliminar el archivo.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      store.dispatch(removeLoader());
    }
  };

  export const delete_archivo_oferta = async (crm_documentacio_id) => {
    try {
      store.dispatch(setLoader("Eliminando archivo"));
      const tipo = "oferta";
      const res = await http.delete(`/contratos/archivos`, {
        params: { crm_documentacio_id, tipo },
      });
      return res.data;
    } catch (err) {
      toast.error("Error al eliminar el archivo.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      store.dispatch(removeLoader());
    }
  };