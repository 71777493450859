import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import './OfertasFilter.css';

const OfertasFilter = ({ onFilter, onExport }) => {
    const [poliza, setPoliza] = useState("");
    const [cliente, setCliente] = useState("");
    const [cups, setCups] = useState("");
    const [nif, setNif] = useState("");
    const [ofertaFrom, setOfertaFrom] = useState("");
    const [ofertaTo, setOfertaTo] = useState("");
    const [sector, setSector] = useState("");
    const [tipo, setTipo] = useState("");
    const [etapa, setEtapa] = useState([]);

    const sectorOptions = [
        { value: "", label: "Todos" },
        { value: "electrico", label: "Eléctrico" },
        { value: "gas", label: "Gas" }
    ];

    const tipoOptions = [
        { value: "", label: "Todos" },
        { value: "simple", label: "Contrato nuevo" },
        { value: "renovacion", label: "Renovación" }
    ];

    const stageOptions = [
        { value: 9, label: "Solicitud oferta" },
        { value: 11, label: "Oferta aceptada" },
        { value: 13, label: "Contrato enviado" },
        { value: 14, label: "Firmado" },
        { value: 15, label: "Pendiente solicitud ATR" },
        { value: 16, label: "Solicitud ATR" },
        { value: 17, label: "Aceptada sol. ATR" },
        { value: 18, label: "Rechazada sol. ATR" },
        { value: 19, label: "Contrato activado" }
    ];


    const handleFilter = (e) => {
        e.preventDefault();
        onFilter({ poliza, cliente, cups, nif, sector, tipo, etapa, ofertaFrom, ofertaTo });
    };

    const handleReset = () => {
        setPoliza("");
        setCliente("");
        setNif("");
        setCups("");
        setSector("");
        setTipo("");
        setEtapa([]);
        setOfertaFrom("");
        setOfertaTo("");
    };

    const isFormValid = () => {
        return isDateRangeValid(ofertaFrom, ofertaTo);
    }

    const isDateRangeValid = (dateFrom, dateTo) => {
        const isRangeEmpty = (!dateFrom && !dateTo);
        const isRangeValid = (dateFrom && dateTo) ? new Date(dateFrom) <= new Date(dateTo) : isRangeEmpty;

        return (isRangeValid  || isRangeEmpty)
    }

    return (
        <Form onSubmit={handleFilter}>
            <Row >
                <Col>
                    <Form.Group>
                        <Form.Label>Sector</Form.Label>
                        <Form.Select
                            className="form-control"
                            value={sector}
                            onChange={(e) => setSector(e.target.value)}
                        >
                        {sectorOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <Form.Label>Tipo oferta</Form.Label>
                        <Form.Select
                            className="form-control"
                            value={tipo}
                            onChange={(e) => setTipo(e.target.value)}
                        >
                        {tipoOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <Form.Label>Nº Oferta</Form.Label>
                        <Form.Control
                            type="text"
                            value={poliza}
                            onChange={(e) => setPoliza(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <Form.Label>Fecha oferta desde</Form.Label>
                        <Form.Control
                            type="date"
                            value={ofertaFrom}
                            onChange={(e) => setOfertaFrom(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Fecha oferta hasta</Form.Label>
                        <Form.Control
                            type="date"
                            value={ofertaTo}
                            onChange={(e) => setOfertaTo(e.target.value)}
                        />
                        {!isDateRangeValid(ofertaFrom, ofertaTo) && (
                            <Form.Text className="text-danger">
                                El rango de fechas no es válido.
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Etapa</Form.Label>
                        <Select
                            classNamePrefix="etapa-select"
                            options={stageOptions}
                            isMulti
                            value={etapa}
                            placeholder=""
                            onChange={(e) => setEtapa(e)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Cliente</Form.Label>
                        <Form.Control
                            type="text"
                            value={cliente}
                            onChange={(e) => setCliente(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>CIF</Form.Label>
                        <Form.Control
                            type="text"
                            value={nif}
                            onChange={(e) => setNif(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>CUPS</Form.Label>
                        <Form.Control
                            type="text"
                            value={cups}
                            onChange={(e) => setCups(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                <Col className="d-flex align-items-end">
                    <Button
                        variant="outline-secondary"
                        className="me-2"
                        type="button"
                        onClick={handleReset}
                        >
                        Limpiar
                    </Button>

                    <Button
                        className="button btn btn-primary"
                        type="submit"
                        disabled={!isFormValid()}>
                        Buscar
                    </Button>

                    <Button
                        variant="outline-secondary"
                        className="ms-2"
                        type="button"
                        disabled={!isFormValid()}
                        onClick={onExport}
                        >
                        Exportar
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default OfertasFilter;