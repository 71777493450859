import http from "../utils/http";
import { toast } from "react-toastify";
import { store } from "../store";
import { removeLoader, setLoader } from "../redux/actions/loader";

export const get_contratos = async (user, p = 1, agents, filters) => {
  try {
    const cleanedFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != null && v !== "")
    );

    if (cleanedFilters.status) {
      cleanedFilters.status = cleanedFilters.status.map(e => e.value);
    }

    store.dispatch(setLoader("Cargando contratos"));
    const res = await http.get(`/contratos/contratos`, { params: { user, p, agents, ...cleanedFilters } });
    return res.data;
  } catch (err) {
    toast.error("Error al obtener los contratos.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};



export const get_facturas = async (polissa_name, sector_type) => {
  try {
    const res = await http.get(`/contratos/archivos`, {
      params: {
        getFacturasByContratoId: "getFacturasByContratoId",
        polissa_name,
        sector_type,
      },
    });
    return res.data;
  } catch (err) {
    toast.error("Error al obtener las facturas.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
  }
};

export const get_factura_by_id = async (id, sector_type) => {
  try {
    store.dispatch(setLoader("Cargando factura"));
    const res = await http.get(`/contratos/archivos`, {
      params: {
        getFacturaById: "getFacturaById",
        id,
        sector_type,
      },
    });
    return res.data;
  } catch (err) {
    toast.error("Error al obtener la factura.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const get_contratos_excel = async (user, agents, filters) => {
  try {
    const cleanedFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != null && v !== "")
    );

    if (cleanedFilters.etapa) {
      cleanedFilters.etapa = cleanedFilters.etapa.map(e => e.label);
    }

    store.dispatch(setLoader("Generando excel"));

    const report = "excel";
    const p = 1;
    const res = await http.get(`/contratos/contratos`, {
      params: { user, p, report, agents, ...cleanedFilters },
      responseType: 'blob',
    });

    // Create blob and download
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1].replace(/"/g, ''));
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);

    return true;
  } catch (err) {
    toast.error("Error al generar el excel de ofertas.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};
