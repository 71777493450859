import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import './ContratosFilter.css';

const ContratosFilter = ({ onFilter, onExport }) => {
    const [poliza, setPoliza] = useState("");
    const [cliente, setCliente] = useState("");
    const [cups, setCups] = useState("");
    const [nif, setNif] = useState("");
    const [sector, setSector] = useState("");
    const [status, setStatus] = useState([]);
    const [altaFrom, setAltaFrom] = useState("");
    const [altaTo, setAltaTo] = useState("");
    const [bajaFrom, setBajaFrom] = useState("");
    const [bajaTo, setBajaTo] = useState("");


    const sectorOptions = [
        { value: "", label: "Todos" },
        { value: "electrico", label: "Electricidad" },
        { value: "gas", label: "Gas" }
    ];

    const statusOptions = [
        { value: "activa", label: "Activa" },
        { value: "baixa", label: "Baja" },
        { value: "cancelada", label: "Cancelada" },
        { value: "esborrany", label: "Borrador" },
        { value: "impagament", label: "Impaga" },
        { value: "modcontractual", label: "Mod. Contractual Abierta" },
        { value: "pendent", label: "Pendiente" },
        { value: "tall", label: "Alta" },
        { value: "validar", label: "Validar" }
    ]

    const handleFilter = (e) => {
        e.preventDefault();
        onFilter({ poliza, cliente, cups, nif, sector, status, altaFrom, altaTo, bajaFrom, bajaTo });
    };

    const handleReset = () => {
        setPoliza("");
        setCliente("");
        setNif("");
        setCups("");
        setSector("");
        setStatus([]);
        setAltaFrom("");
        setAltaTo("");
        setBajaFrom("");
        setBajaTo("");
    };

    const isFormValid = () => {
        return (isDateRangeValid(altaFrom, altaTo) && isDateRangeValid(bajaFrom, bajaTo));
    }

    const isDateRangeValid = (dateFrom, dateTo) => {
        const isRangeEmpty = (!dateFrom && !dateTo);
        const isRangeValid = (dateFrom && dateTo) ? new Date(dateFrom) <= new Date(dateTo) : isRangeEmpty;

        return (isRangeValid  || isRangeEmpty)
    }

    return (
        <Form onSubmit={handleFilter}>
            <Row >
                <Col>
                    <Form.Group>
                        <Form.Label>Sector</Form.Label>
                        <Form.Select
                            className="form-control"
                            value={sector}
                            onChange={(e) => setSector(e.target.value)}
                        >
                        {sectorOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <Form.Label>Nº contrato</Form.Label>
                        <Form.Control
                            type="text"
                            value={poliza}
                            onChange={(e) => setPoliza(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <Form.Label>Alta desde</Form.Label>
                        <Form.Control
                            type="date"
                            value={altaFrom}
                            onChange={(e) => setAltaFrom(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Alta hasta</Form.Label>
                        <Form.Control
                            type="date"
                            value={altaTo}
                            onChange={(e) => setAltaTo(e.target.value)}
                        />
                        {!isDateRangeValid(altaFrom, altaTo) && (
                            <Form.Text className="text-danger">
                                El rango de fechas no es válido.
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <Form.Label>Baja desde</Form.Label>
                        <Form.Control
                            type="date"
                            value={bajaFrom}
                            onChange={(e) => setBajaFrom(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <Form.Label>Baja hasta</Form.Label>
                        <Form.Control
                            type="date"
                            value={bajaTo}
                            onChange={(e) => setBajaTo(e.target.value)}
                        />
                        {!isDateRangeValid(bajaFrom, bajaTo) && (
                            <Form.Text className="text-danger">
                                El rango de fechas no es válido.
                            </Form.Text>
                        )}
                    </Form.Group>
                </Col>

            </Row>
            <Row className="mt-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Estado</Form.Label>
                        <Select
                            classNamePrefix="status-select"
                            options={statusOptions}
                            isMulti
                            value={status}
                            placeholder=""
                            onChange={(e) => setStatus(e)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Cliente</Form.Label>
                        <Form.Control
                            type="text"
                            value={cliente}
                            onChange={(e) => setCliente(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>CIF</Form.Label>
                        <Form.Control
                            type="text"
                            value={nif}
                            onChange={(e) => setNif(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>CUPS</Form.Label>
                        <Form.Control
                            type="text"
                            value={cups}
                            onChange={(e) => setCups(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col>&nbsp;</Col>
                <Col className="d-flex align-items-end">
                    <Button
                        variant="outline-secondary"
                        className="me-2"
                        type="button"
                        onClick={handleReset}
                        >
                        Limpiar
                    </Button>

                    <Button
                        className="button btn btn-primary"
                        type="submit"
                        disabled={!isFormValid()}>
                        Buscar
                    </Button>

                    <Button
                        variant="outline-secondary"
                        className="ms-2"
                        type="button"
                        disabled={!isFormValid()}
                        onClick={onExport}
                        >
                        Exportar
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default ContratosFilter;