import { connect } from "react-redux";
import { useState } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
import { post_archivo } from "../../services/documentos";

function SubirArchivo({ contrato, show, close }) {
  const [file, setFile] = useState(false);
  const [descrip, setDescrip] = useState("");

  const cerrar = () => {
    close(false);
  };

  const subirArchivo = async () => {
    const params = {
      descrip,
      polissa_id: contrato.id,
      sector_type: contrato.sector_type,
    };
    try {
      await post_archivo(file, params);
      cerrar();
    } catch (error) {}
  };

  return (
    <Modal
      show={show}
      size="md"
      backdrop="static"
      className="border border-0"
      centered
    >
      <Modal.Header className="ab-primary justify-content-between align-items-center">
        <Col md="auto">
          <h2 className="text-white my-0">Cargar archivo</h2>
        </Col>
        <Col md="auto">
          <h2 className="text-white my-0">Nº {contrato.name}</h2>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-start align-items-center mb-2">
          <Col
            md="auto"
            className="d-flex flex-column align-items-start text-black"
          >
            <label>Tipo de archivo</label>
            <Form.Select className="form-control mb-1" size="sm" onChange={e=>setDescrip(e.target.value)}>
              <option selected value="">Seleccionar tipo</option>
              <option value="CONTRATO">Contrato firmado</option>
              <option value="DOCUMENTO">Documentación personal</option>
              <option value="OTROS">Otros</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="justify-content-start align-items-center">
          <Col className="d-flex flex-column align-items-start text-black">
            <label>Documento</label>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col md="auto">
            <Button
              disabled={!descrip || !file}
              className="button rounded-5"
              onClick={() => subirArchivo()}
            >
              Guardar
            </Button>
          </Col>
          <Col md="auto">
            <Button
              className="button rounded-5 button-secondary"
              variant="secondary"
              onClick={cerrar}
            >
              Cerrar
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default connect()(SubirArchivo);
