import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./routes/ProtectedRoute";
import AdminRoute from "./routes/AdminRoute";

import Signup from "./containers/auth/Signup";
import Login from "./containers/auth/Login";
import Activate from "./containers/auth/Activate";
import ResetPassword from "./containers/auth/ResetPassword";
import ResetPasswordConfirm from "./containers/auth/ResetPasswordConfirm";

import Error404 from "./containers/errors/Error404";
import Home from "./containers/Home";

import OfertasList from "./containers/ofertas/OfertasList";
import OfertaPartner from "./containers/ofertas/OfertaPartner";
import OfertaPagador from "./containers/ofertas/OfertaPagador";
import OfertaCups from "./containers/ofertas/OfertaCups";
import OfertaTarifa from "./containers/ofertas/OfertaTarifa";

import ContratosList from "./containers/ofertas/ContratosList";
import MiPerfil from "./containers/auth/MiPerfil";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public */}
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />

        {/* Authentication */}
        <Route path="/signup" element={
              <AdminRoute>
                <Signup />
              </AdminRoute>
            }
          />
        <Route path="/profile" element={
              <PrivateRoute>
                <MiPerfil />
              </PrivateRoute>
            }
          />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/activate/:uid/:token" element={<Activate />} />
        <Route exact path="/reset_password" element={<ResetPassword />} />
        <Route exact path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />

        {/* Ofertas */}
        <Route path="/contratos/ofertas" element={
              <PrivateRoute>
                <OfertasList />
              </PrivateRoute>
            }
          />
        <Route path="/:sector_type/tarifas" element={
              <PrivateRoute>
                <OfertaTarifa />
              </PrivateRoute>
            }
          />
        <Route path="/:sector_type/tarifas/:oferta_id" element={
              <PrivateRoute>
                <OfertaTarifa />
              </PrivateRoute>
            }
          />
        <Route path="/:sector_type/partner/:oferta_id" element={
            <PrivateRoute>
              <OfertaPartner />
            </PrivateRoute>
          }
        />
        <Route path="/:sector_type/pagador/:oferta_id" element={
            <PrivateRoute>
              <OfertaPagador />
            </PrivateRoute>
          }
        />
        <Route path="/:sector_type/cups/:oferta_id" element={
            <PrivateRoute>
              <OfertaCups />
            </PrivateRoute>
          }
        />
        
        {/* contratos */}
        <Route path="/contratos/contratos" element={
            <PrivateRoute>
              <ContratosList />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
