import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { toast } from "react-toastify";
import './UserAutoCompleteInput.css';
import { searchUsers } from '../../services/usuarios';

const UserAutocompleteInput = ({ onChange, value, placeholder }) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState(value);

    const loadOptions = debounce(async (inputValue, callback) => {
        if (inputValue.length < 3) {
            return callback([]);
        }

        try {
            const users = await searchUsers(inputValue);

            const options = users.map(user => ({
                value: user.prescriptor,
                label: user.name,
            }));
            callback(options);
        } catch (error) {
            toast.error('Error al obtener los usuarios', {
                position: toast.POSITION.TOP_CENTER,
            });
            callback([]);
        }
    }, 300);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        onChange(selectedOption);
    };

    return (
        <div className="row mb-4 mt-0 d-flex justify-content-center align-items-center">
            <div className="col-md-3" style={{ textAlign: 'right' }}>Seleccionar usuario:</div>
            <div className="col-md-5 ml-auto">
                <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    onInputChange={setInputValue}
                    onChange={handleChange}
                    value={value}
                    placeholder={placeholder}
                    inputValue={inputValue}
                    defaultInputValue={selectedOption ? selectedOption.label : ''}
                    defaultOptions
                    className="user-select-container"
                    classNamePrefix="user-select"
                />
            </div>
        </div>
    );
};

UserAutocompleteInput.propTypes = {
    value: PropTypes.object,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default UserAutocompleteInput;