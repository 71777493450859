import http from "../utils/http";
import { toast } from "react-toastify";

export const getSupervisors = async () => {
    try {
        const res = await http.get(`/users/supervisors`);
        return res.data;
    } catch (err) {
        toast.error("Error al obtener los supervisores", {
            position: toast.POSITION.TOP_CENTER,
        });
    }
}

export const searchUsers = async (query) => {
    try {
        const res = await http.get(`/users/search?q=${query}`);
        return res.data;
    } catch (err) {
        toast.error("Error al obtener los usuarios", {
            position: toast.POSITION.TOP_CENTER,
        });
    }
}