import Layout from "../../hocs/Layout";
import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, Row, Container, Card } from "react-bootstrap";
import { TextInput } from "../../components/inputs/Inputs";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { perfilSchema } from "../../utils/validationSchemas";
import { patch_user } from "../../redux/actions/auth";

const OfertaTarifa = ({ user, patch_user }) => {
  const defaultValues = {
    email: "",
    first_name: "",
    last_name: "",
    document_id: "",
    phone: "",
    responsible: "",
    prescriptor: "",
    bank_account: "",
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty, errors, dirtyFields },
  } = useForm({
    mode: "onBlur",
    defaultValues,
    resolver: yupResolver(perfilSchema),
  });

  const resetData = useCallback(() => {
    reset({
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      document_id: user.document_id,
      phone: user.phone,
      responsible: user.responsible,
      prescriptor: user.prescriptor,
      bank_account: user.bank_account,
    });
  }, [user, reset]);

  useEffect(() => {
    resetData();
  }, [resetData]);

  const [editMode, setEditMode] = useState(false);

  const cancelEditar = () => {
    setEditMode(false);
  };

  const onSubmit = async () => {
    const form = {};
    Object.entries(getValues())
      .filter(([key, value]) => !!dirtyFields[key])
      .forEach((value) => (form[value[0]] = value[1]));
    try {
      await patch_user(form);
      setEditMode(false);
    } catch (error) {
      resetData();
    } finally {
    }
  };

  return (
    <Layout>
      <Container className=" align-items-center justify-content-center mt-5">
        <Row className="align-items-center justify-content-center">
          <Col md="8" className="justify-content-center d-flex">
            <Card>
              <Card.Header className="ab-primary-elec">
                <Row className="text-center align-items-center justify-content-center my-1">
                  <Col>
                    <h1 className="col-auto text-white form-label m-0">
                      Mi perfil
                    </h1>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="">
                <Row className="justify-content-center align-items-center mt-3">
                  <Col>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row className="justify-content-start mb-2">
                        <Col
                          md={6}
                          className="d-flex flex-column align-items-start"
                        >
                          <TextInput
                            register={register}
                            name="document_id"
                            label="Documento"
                            error={errors.document_id?.message}
                            readOnly={!editMode}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-start mb-2">
                        <Col
                          md={6}
                          className="d-flex flex-column align-items-start"
                        >
                          <TextInput
                            register={register}
                            name="first_name"
                            label="Nombre/Razón social"
                            error={errors.first_name?.message}
                            readOnly={!editMode}
                          />
                        </Col>
                        <Col
                          md={6}
                          className="d-flex flex-column align-items-start"
                        >
                          <TextInput
                            register={register}
                            name="last_name"
                            label="Apellido"
                            error={errors.last_name?.message}
                            readOnly={!editMode}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-start mb-2">
                        <Col
                          md={6}
                          className="d-flex flex-column align-items-start"
                        >
                          <TextInput
                            register={register}
                            name="email"
                            label="Correo electrónico"
                            readOnly
                            disabled={editMode}
                          />
                        </Col>
                        <Col
                          md={6}
                          className="d-flex flex-column align-items-start"
                        >
                          <TextInput
                            register={register}
                            name="phone"
                            label="Teléfono"
                            error={errors.phone?.message}
                            readOnly={!editMode}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-start mb-2">
                        <Col
                          md={6}
                          className="d-flex flex-column align-items-start"
                        >
                          <TextInput
                            register={register}
                            name="responsible"
                            label="Gestor de cuenta"
                            error={errors.responsible?.message}
                            readOnly
                            disabled={editMode}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-start mb-2">
                        <Col
                          md={6}
                          className="d-flex flex-column align-items-start"
                        >
                          <TextInput
                            register={register}
                            name="prescriptor"
                            label="Prescriptor"
                            error={errors.prescriptor?.message}
                            readOnly
                            disabled={editMode}
                          />
                        </Col>
                        <Col
                          md={6}
                          className="d-flex flex-column align-items-start"
                        >
                          <TextInput
                            register={register}
                            name="bank_account"
                            label="IBAN"
                            error={errors.bank_account?.message}
                            readOnly
                            disabled={editMode}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col lg={12} md={12}>
                          <Row className="justify-content-evenly mx-5">
                            <Col>
                              {editMode ? (
                                <Row>
                                  <Col md={6}>
                                    <Button
                                      className="button rounded-5 button-block mb-2"
                                      type="submit"
                                      disabled={!isDirty}
                                    >
                                      Guardar
                                    </Button>
                                  </Col>
                                  <Col md={6}>
                                    <Button
                                      className="button rounded-5 button-block mb-2"
                                      onClick={() => cancelEditar()}
                                    >
                                      Cancelar
                                    </Button>
                                  </Col>
                                </Row>
                              ) : (
                                <Row className="justify-content-center">
                                  <Col md={6}>
                                    <Button
                                      className="button rounded-5 button-block mb-2"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setEditMode(true)}}
                                    >
                                      Editar
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  user: state.Auth.user,
});
export default connect(mapStateToProps, { patch_user })(OfertaTarifa);
