import http from "../utils/http";
import { toast } from "react-toastify";


export const getNotasOferta = async (oferta_id) => {
    try {
        const res = await http.get(`/contratos/notas`, {
            params: {
                oferta_id,
            },
        });
        return res.data;
    } catch (err) {
        toast.error("Error al obtener las notas de la oferta.", {
            position: toast.POSITION.TOP_CENTER,
        });
        return [];
    }
};
