import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

function AdminRoute({ children, isSuperAdmin }) {
    return isSuperAdmin ? <>{children}</> : <Navigate to="/login" />;
}

const mapStateToProps = (state) => ({
    isSuperAdmin: state.Auth.isSuperAdmin
})

export default connect(mapStateToProps)(AdminRoute);