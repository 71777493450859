import http from "../utils/http";
import { toast } from "react-toastify";
import { store } from "../store";
import { removeLoader, setLoader } from "../redux/actions/loader";

export const set_oferta_erp = async (oferta_id, datos, sector_type) => {
  const body = { oferta_id, datos, sector_type };
  try {
    store.dispatch(setLoader("Actualizando oferta"));
    const res = await http.put(`/contratos/ofertas`, body, {
      params: { set_oferta_erp: "set_oferta_erp" },
    });
    return res.data.oferta;
  } catch (err) {
    toast.error("Error al modificar la oferta.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const copiar_ctto_actual = async (numero_ctto, sector_type, user, type_oferta, subtipo_oferta) => {
  const body = {
    numero_ctto,
    sector_type,
    user,
    type_oferta,
    subtipo_oferta,
  };
  try {
    store.dispatch(setLoader("Buscando contrato"));
    const res = await http.put(`/contratos/ofertas`, body, {
      params: { copiar_ctto_actual: "copiar_ctto_actual" },
    });
    toast.success("Contrato cargado correctamente.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    return res.data.oferta;
  } catch (err) {
    toast.error("Error al buscar el contrato", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const create_oferta_erp = async (user, datos) => {
  const body = { user, datos };
  try {
    store.dispatch(setLoader("Creando oferta"));
    const res = await http.post(`/contratos/ofertas`, body, {
      params: { create_oferta_erp: "create_oferta_erp" },
    });
    toast.success("Oferta creada correctamente.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    return res.data.oferta;
  } catch (err) {
    toast.error("Error al crear la oferta.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const create_oferta_renovacion_erp = async (user, datos) => {
  const body = {
    user,
    datos,
  };
  try {
    store.dispatch(setLoader("Creando oferta"));
    const res = await http.post(`/contratos/ofertas`, body, {
      params: { create_oferta_renovacion_erp: "create_oferta_renovacion_erp" },
    });
    return res.data.oferta;
  } catch (err) {
    toast.error("Error al crear la oferta de renovación.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const get_ofertas_erp = async (user, p = 1, agents, filters) => {
  try {
    const cleanedFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != null && v !== "")
    );

    if (cleanedFilters.etapa) {
      cleanedFilters.etapa = cleanedFilters.etapa.map(e => e.label);
    }

    store.dispatch(setLoader("Cargando ofertas"));
    const res = await http.get(`/contratos/ofertas`, { params: { user, p, agents, ...cleanedFilters } });
    return res.data;
  } catch (err) {
    toast.error("Error al obtener las ofertas.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const get_ofertas_excel = async (user, agents, filters) => {
  try {
    const cleanedFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != null && v !== "")
    );

    if (cleanedFilters.etapa) {
      cleanedFilters.etapa = cleanedFilters.etapa.map(e => e.label);
    }

    store.dispatch(setLoader("Generando excel"));

    const report = "excel";
    const res = await http.get(`/contratos/ofertas`, {
      params: { user, report, agents, ...cleanedFilters },
      responseType: 'blob',
    });

    // Create blob and download
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1].replace(/"/g, ''));
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);

    return true;
  } catch (err) {
    toast.error("Error al generar el excel de ofertas.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};



export const get_datos_oferta_erp = async (oferta_id) => {
  try {
    store.dispatch(setLoader("Cargando oferta"));
    const res = await http.get(`/contratos/ofertas`, { params: { oferta_id } });
    return res.data.oferta;
  } catch (err) {
    toast.error("Error al obtener la oferta.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

export const aceptar_oferta = async (oferta_id, user, sector_type) => {
  const body = { oferta_id, user, sector_type };
  try {
    store.dispatch(setLoader("Aceptando oferta"));
    await http.post(`/contratos/ofertas`, body, {
      params: { aceptar_oferta: "aceptar_oferta" },
    });
    toast.success("Oferta aceptada correctamente.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } catch (err) {
    const mensaje = err.response.data.error;
    toast.error(mensaje, {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};

