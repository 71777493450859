import http from "../utils/http";
import { toast } from "react-toastify";
import { store } from "../store";
import { removeLoader, setLoader } from "../redux/actions/loader";



export const get_archivo_signaturit_by_id = async (signaturit_id, document_id) => {
  try {
    store.dispatch(setLoader("Cargando archivo"));
    const res = await http.get(`/signaturit/get-signed-document`, {
      params: {
        signaturit_id,
        document_id,
      },
    });
    return res.data;
  } catch (err) {
    toast.error("Error al obtener el archivo de Signaturit.", {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};


export const send_document_signaturit = async (oferta_id, signature_type) => {
  const body = { oferta_id,signature_type};
  try {
    store.dispatch(setLoader("Enviando documento a firmar"));
    await http.post(`/signaturit/send-document`, body);
    toast.success("Documento enviado a firmar correctamente.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } catch (err) {
    const mensaje = err.response.data.error;
    toast.error(mensaje, {
      position: toast.POSITION.TOP_CENTER,
    });
  } finally {
    store.dispatch(removeLoader());
  }
};




